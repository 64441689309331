import { enums } from '@optimizely/react-sdk'

const devCfg = {
    contentful: {
        environment: 'dev',
        deliveryToken: 'WGroDYrTf3MxEIssYVSDvNL77O_fJ52nFN9bhKjFye4',
        previewToken: 'TcJ3g_0o6bU4svf-2RbAYO9zsaBdpsKU4yef9YgzbvM',
    },
    ga: {
        code: 'UA-165909836-2'
    },
    optimizely: {
        key: 'Wm4eDbxUt9Rc3zv3RJbxkK',
        loglevel: enums.LOG_LEVEL.INFO
    }
};

const prodCfg = {
    contentful: {
        environment: 'master',
        deliveryToken: 'ZELyb0ISzrXf-nwt5SgSEVYBrb53MuvKaY9IvwHoCbs',
        previewToken: 'G1bm612QIgZwPZ6XvejyAIBU-nRrswYZ4SvyT-ht9sk',
    },
    ga: {
        code: 'UA-165909836-1'
    },
    optimizely: {
        key: 'Do1eGrDeavYZ4inxw9LcbU',
        loglevel: enums.LOG_LEVEL.NOTSET
    }
};

const config = process.env.REACT_APP_STAGE === 'production' ? prodCfg : devCfg;

export default {
    // merge environment-specifc config
    ...config,
    siteName: 'Global Centre of Possibility',
    contentful: {
        spaceId: 'untm9a3pquyq',
        ...config.contentful,
    },
};
